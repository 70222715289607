import React from 'react';
import { useAuthContext } from "../../provider/authProvider"
import { Link } from "gatsby";

const ProfilBought = ( { className } ) => {
  const authContext = useAuthContext();

  return <div className={className}>

    <h3>Ihre gekauften Elemente</h3>

    { ( !(authContext?.currentUser?.bought_events) || authContext?.currentUser?.bought_events?.length === 0 ) &&
    <p>Sie haben keine Elemente gekauft</p>
    }


    { authContext.currentUser?.bought_events.map(
      (b) => {
        return <Link to={"/events/" + b.slug} className={"my-2 d-block"}>
          { b.title }
        </Link>
      }
    )}

  </div>
};

export default ProfilBought;
