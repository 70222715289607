import React from 'react';
import { useAuthContext } from "../../provider/authProvider"
import * as styles from './profil-data.module.scss';
import * as moment from "moment"

const ProfilData = ( { className = '' } ) => {
  const authContext = useAuthContext();

  return <div className={className}>
    <h3>
      Ihre Daten
    </h3>

    {/*<Formik*/}
    {/*  initialValues={*/}
    {/*    {*/}
    {/*      mail: '',*/}
    {/*      password: ''*/}
    {/*    }*/}
    {/*  }*/}
    {/*>*/}

    {/*</Formik>*/}

    <p>
      <b>E-Mail: </b>
      { authContext?.currentUser?.email ? authContext.currentUser.email : <i>Lade ...</i> }
    </p>


    <h3 className={"mt-5"}>Mitgliedschaft RC Academy</h3>
    <p>

      { authContext?.currentUser?.premium && <>
        <span className={styles.active}>Aktive Premium-Mitgliedschaft bis { moment(authContext?.currentUser?.aboUntil).format("DD.MM.yyyy") }</span>
        <div className={styles.activeSub + " mt-1"}>
            Sie haben durch Ihre aktive Premium Mitgliedschaft Zugriff auf alle Premium Wissensartikel.<br />
            Noch inkludierte Webinar-Teilnahmen: { authContext.currentUser.includedWebinars }
        </div>
      </>}

      { !authContext?.currentUser?.premium && <span>Zur Zeit keine aktive Mitgliedschaft</span>}

    </p>

  </div>
};

export default ProfilData;
