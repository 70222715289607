import React from 'react';
import * as styles from './profil.module.scss';

import Layout from "../page-components/layout"
import { useAuthContext } from "../provider/authProvider"
import ProfilData from "../pages-parts/profil/profil-data"
import ProfilBought from "../pages-parts/profil/profil-bought"
import ProfilUnlock from "../pages-parts/profil/profil-unlock"
import RcDefaultPageTeaser from "../page-components/teaser/rcDefaultPageTeaser"

const ProfilPage = () => {

  return <Layout>

    <RcDefaultPageTeaser>
      <h1>Ihr Profil</h1>
      <p>Verwalten Sie hier Ihr Profil</p>
    </RcDefaultPageTeaser>

    <div className={"container bg-white h-100 " + styles.profilPage}>
      <div className="row">

        <div className="col-12">

          <div className="px-5 pb-5">
            <ProfilData className={"mt-5"} />
            <ProfilBought className={"mt-5"} />
            {/*<ProfilUnlock className={"my-5"} />*/}
          </div>

        </div>
      </div>
    </div>
  </Layout>

};

export default ProfilPage;
